import React, { ReactElement, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CommunityContext } from '../../communityConfigs/communityContextProvider';
import GenericButton from '../../components/genericButton/genericButton';
import { portalOptions } from '../../types/portalOptions';
import style from './help.module.css';

type HelpPageProps = {
  role?: portalOptions;
};
export default function HelpPage(props: HelpPageProps): ReactElement {
  const { role } = props;
  const history = useHistory();

  const { zendeskURLs } = useContext(CommunityContext);

  useEffect(() => {
    if (role && zendeskURLs) {
      const finalURL = zendeskURLs[role as keyof typeof zendeskURLs];
      if (finalURL && typeof finalURL === 'string') {
        window.location.replace(finalURL);
      }
    }
  }, [role, zendeskURLs]);

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <div className={`${style.subHeader} mainColor `}>
          <div className={`${style.circle} white `} />
          <h1 className={`${style.subHeaderTitle} textColorWhite `}>Help</h1>

          <span />
          <div className={style.backBtn}>
            <GenericButton
              color="yellow"
              // size="auto"
              title="Back"
              clickHandler={history.goBack}
            />
          </div>
        </div>
        <div>{/* add iframe containing video here */}</div>
      </div>
    </div>
  );
}
HelpPage.defaultProps = {
  role: undefined,
};
