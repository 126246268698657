import { gql, useMutation } from '@apollo/client';
import React, { ReactElement, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import '../../../App';
import { Footer } from '../../../components/footer/footer';
import { CommunityContext } from '../../communityConfigs/communityContextProvider';
import GenericAlert from '../../components/genericAlert/genericAlert';
import GenericButton from '../../components/genericButton/genericButton';
import InputField from '../../components/inputField/inputField';
import { portalOptions } from '../../types/portalOptions';
import store from '../../utilities/store';
import style from './createAccount.module.css';
import useCreateAccountTexts from './createAccountTexts';

const genRegisterMutation = (role: string) => gql`
  mutation RegisterUser(
    $firstName: String!,
    $lastName: String!,
    $email: String!,
    $password: String!,
    ${role === 'guest' ? '$communityIdentifier: String!,' : ''}
    ${role === 'resident' ? '$registrationCode: String!,' : ''}
  ) {
    registerUser(
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      password: $password,
      ${role === 'guest' ? 'communityIdentifier: $communityIdentifier,' : ''}
      ${role === 'resident' ? 'registrationCode: $registrationCode,' : ''}
    ) {
      success
      error
      token
      data {
        userId
        firstName
        lastName
        email
        roles
        communityCode
        applicationId
        profileStatus
      }
    }
  }
`;

// const temporaryResidentMutation = () => gql`
//   mutation registerTempResident(
//     $firstName: String!
//     $lastName: String!
//     $email: String!
//     $password: String!
//     $registrationCode: String!
//   ) {
//     registerTempResident(
//       firstName: $firstName
//       lastName: $lastName
//       email: $email
//       password: $password
//       registrationCode: $registrationCode
//     ) {
//       success
//       error
//       data {
//         registrationId
//         _id
//         firstName
//         lastName
//         email
//         communityCode
//       }
//     }
//   }
// `;

interface Props {
  role: portalOptions;
  successHref: string;
}

export default function CreateAccount(props: Props): ReactElement {
  const { communityId } = useContext(CommunityContext);
  const { role, successHref } = props;
  const history = useHistory();
  const [registrationCode, setRegistrationCode] = useState<string>('');
  const [fname, setFname] = useState<string>('');
  const [lname, setLname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const { bigLogo, communityId: communityCode } = useContext(CommunityContext);
  const { communityTitle, communitySubTitle, alertText } = useCreateAccountTexts(role);

  const hiddenAlert = () => {
    setTimeout(() => {
      setAlert('');
    }, 4000);
  };

  const [doRegister] = useMutation(genRegisterMutation(role), {
    onError: e => {
      setAlert(e.message);
      setAlertColor('red');
      hiddenAlert();
    },
    onCompleted: d => {
      if (d.registerUser.success) {
        store.dispatch({
          type: 'user/login',
          payload: {
            token: d.registerUser.token,
            user: {
              ...d.registerUser.data,
              communityCode: d.registerUser.data.communityIdentifier,
            },
          },
        });
        const roles = d?.registerUser?.data?.roles || [];
        if (roles.indexOf('resident') > -1) {
          // if resident then go to registration process
          history.push(`/resident/registration/${d?.registerUser?.data?.applicationId}`);
        } else {
          history.push(successHref);
        }
      } else {
        setAlert(d.registerUser.error);
        setAlertColor('red');
        hiddenAlert();
      }
    },
  });

  // const [doTempRegister] = useMutation(temporaryResidentMutation(), {
  //   onError: e => {
  //     setAlert(e.message);
  //     setAlertColor('red');
  //     hiddenAlert();
  //   },
  //   onCompleted: d => {
  //     if (d.registerTempResident.success) {
  //       store.dispatch({
  //         type: 'user/login',
  //         payload: {
  //           token: d.registerTempResident.token,
  //           user: {
  //             ...d.registerTempResident.data,
  //             communityCode: d.registerTempResident.data.communityIdentifier,
  //           },
  //         },
  //       });
  //       history.push(`/resident/registration/${d.registerTempResident.data._id}`);
  //     } else {
  //       setAlert(d.registerTempResident.error);
  //       setAlertColor('red');
  //       hiddenAlert();
  //     }
  //   },
  // });

  const submitHandler = () => {
    // const checkedCC = ['resident'].includes(role) ? registrationCode : communityPicker();
    // console.log(checkedCC);
    if ([fname, lname, email, password].some((i: string) => i === '')) {
      setAlert('Please fill out the required info');
      setAlertColor('red');
      hiddenAlert();
    } else if (confirmPassword !== password) {
      setAlert('Those passwords do not match');
      setAlertColor('red');
      hiddenAlert();
    } else {
      // if (role === 'resident') {
      //   doTempRegister({
      //     variables: {
      //       communityIdentifier: communityCode,
      //       registrationCode,
      //       firstName: fname,
      //       lastName: lname,
      //       email,
      //       password,
      //     },
      //   });
      // } else {
      doRegister({
        variables: {
          communityIdentifier: communityCode,
          registrationCode,
          firstName: fname,
          lastName: lname,
          email,
          password,
        },
      });
      // }
    }
  };

  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <form
          className={style.box__left}
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            submitHandler();
          }}
        >
          <div className={style.box__titleBoxLeft}>
            <h3 className={`${style.box__mainTitleLeft} textColorDark `}>
              {communityTitle}
            </h3>
            <p className={`${style.box__paragraph} textColorMedium `}>
              {communitySubTitle}
            </p>
            {role === 'resident' && communityCode === 'palmettodunes' ? (
              <p className={`${style.box__importantNotice}`}>{alertText}</p>
            ) : null}
          </div>
          <h5 className={`${style.box__subTitle} textColorDark `}>
            Please create your account
          </h5>
          <div className={style.alert}>
            <div className={style.alertBox}>
              <GenericAlert color={alertColor} title={alert} hidden={!alert} />
            </div>
          </div>
          {['resident'].includes(role) ? (
            <div className={style.inputLong}>
              <div className={style.box__inputLong}>
                <div className={style.box__inputField}>
                  <InputField
                    smallSize
                    highlightOnFocus
                    htmlFor="comm-code-inpt"
                    labelTitle="Community Code"
                    inputType="text"
                    inputValue={registrationCode}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setRegistrationCode(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div className={style.box__nameBox}>
            <div className={style.box__inputShort}>
              <InputField
                smallSize
                highlightOnFocus
                htmlFor="first-name-inpt"
                labelTitle="First Name"
                inputType="text"
                inputValue={fname}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFname(e.target.value);
                }}
              />
            </div>
            <div className={style.invisbleBox} />
            <div className={style.box__inputShort}>
              <InputField
                smallSize
                highlightOnFocus
                htmlFor="last-name-inpt"
                labelTitle="Last Name"
                inputType="text"
                inputValue={lname}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setLname(e.target.value);
                }}
              />
            </div>
          </div>
          <div className={style.inputLong}>
            <div className={style.box__inputLong}>
              <div className={style.box__inputField}>
                <InputField
                  smallSize
                  highlightOnFocus
                  htmlFor="email-inpt"
                  labelTitle="Email Address"
                  inputType="email"
                  inputValue={email}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className={style.box__inputLong}>
              <div className={style.box__inputField}>
                <InputField
                  smallSize
                  highlightOnFocus
                  htmlFor="password-inpt"
                  labelTitle="Password"
                  inputType="password"
                  inputValue={password}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className={style.box__inputLong}>
              <div className={style.box__inputField}>
                <InputField
                  smallSize
                  highlightOnFocus
                  htmlFor="verify-password-inpt"
                  labelTitle="Verify Password"
                  inputType="password"
                  inputValue={confirmPassword}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className={style.box__btnLeft}>
            <div className={style.box__btnLeftInnerBox}>
              <GenericButton
                color="blue"
                size="medium"
                title="Create Account"
                type="submit"
              />
            </div>
          </div>
        </form>
        <div className={`${style.box__right}  linerGradient `}>
          <img
            src={bigLogo}
            alt=""
            className={
              communityId === 'watercolor' ||
              communityId === 'oceanlakes' ||
              communityId === 'isleworth' ||
              communityId === 'pinnacleport' ||
              communityId === 'debordieu' ||
              communityId === 'carillonbeach'
                ? style.box__imgPostion
                : style.box__img
            }
          />
          <h1 className={`${style.box__mainTitleRight} textColorWhite `}>
            <div className={style.box__mainTitleRightInnerBox}>
              Do you already have an account?
            </div>
          </h1>
          <Link to="/login">
            <div className={style.box__btnRight}>
              <div className={style.box__btnRightInnerBox}>
                <GenericButton color="white" size="medium" title="Login" />
              </div>
            </div>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}
