export type DurationInfo = {
  type: number;
  duration1: number;
  duration2: number;
};
export type AugmentedDurationInfo = DurationInfo & {
  editable: boolean;
  maxEndDate?: Date;
  minEndDate?: Date;
  endDate?: Date;
  maxStartDate?: Date;
  minStartDate?: Date;
  startDate?: Date;
  numberOfDaysActive?: number;
  maxDuration?: number;
  minDuration?: number;
};

function minDate(...dates: (Date | undefined)[]): Date | undefined {
  const res = Math.min(
    ...dates.map(d => d?.getTime() || Infinity).filter(d => Number.isFinite(d)),
  );
  if (Number.isFinite(res)) return new Date(res);
  return undefined;
}
function maxDate(...dates: (Date | undefined)[]): Date | undefined {
  const res = Math.max(
    ...dates.map(d => d?.getTime() || -Infinity).filter(d => Number.isFinite(d)),
  );
  if (Number.isFinite(res)) return new Date(res);
  return undefined;
}
function type0(
  durationInfo: DurationInfo,
  startDate?: string,
  endDate?: string,
  _blockEdgeCases?: boolean,
): AugmentedDurationInfo {
  // today for reference
  const today = new Date();
  // parse curent values
  // default to today
  const sd = startDate ? new Date(startDate) : undefined;
  const ed = endDate ? new Date(endDate) : undefined;
  const calculatedEndDate = new Date();
  calculatedEndDate.setUTCDate(today.getUTCDate() + durationInfo.duration1);
  // special case expire end of december
  if (!_blockEdgeCases && durationInfo.duration1 === 0) {
    calculatedEndDate.setUTCDate(31);
    calculatedEndDate.setUTCMonth(11);
    // expire next year if already in december
    calculatedEndDate.setUTCFullYear(
      today.getUTCFullYear() + (today.getUTCMonth() === 11 ? 1 : 0),
    );
  }
  return {
    ...durationInfo,
    editable: false,
    minEndDate: calculatedEndDate,
    maxEndDate: calculatedEndDate,
    endDate: ed,
    minStartDate: today,
    maxStartDate: today,
    startDate: sd,
    maxDuration: durationInfo.duration1,
  };
}
function type1(
  durationInfo: DurationInfo,
  startDate?: string,
  endDate?: string,
): AugmentedDurationInfo {
  return {
    ...type0(durationInfo, startDate, endDate, false),
    numberOfDaysActive: durationInfo.duration2,
  };
}
function type2(
  durationInfo: DurationInfo,
  startDate?: string,
  endDate?: string,
): AugmentedDurationInfo {
  // today for reference
  const today = new Date();
  // parse curent values
  // default to today
  const sd = startDate ? new Date(startDate) : undefined;
  const ed = endDate ? new Date(endDate) : undefined;
  // calc max end date
  let maxEndDate: Date | undefined;
  if (sd) {
    maxEndDate = new Date(sd);
    maxEndDate.setUTCDate(sd.getUTCDate() + durationInfo.duration1);
  }
  // calc min start date
  // debugger
  let minStartDate: Date | undefined;
  if (ed) {
    minStartDate = new Date(ed);
    minStartDate.setUTCDate(ed.getUTCDate() - durationInfo.duration1);
  }
  minStartDate = maxDate(today, minStartDate);
  // calc max start date
  let maxStartDate = minDate(ed, maxEndDate);
  if (maxStartDate && minStartDate && maxStartDate < minStartDate) {
    maxStartDate = maxDate(maxStartDate, minStartDate);
  }
  // special case expire end of december
  return {
    ...durationInfo,
    editable: true,
    minEndDate: maxDate(today, sd),
    maxEndDate,
    endDate: ed,
    minStartDate,
    maxStartDate,
    startDate: sd,
    maxDuration: durationInfo.duration1,
  };
}

export function augmentDurationInfo(
  durationInfo: DurationInfo,
  startDate?: string,
  endDate?: string,
): AugmentedDurationInfo {
  switch (durationInfo.type) {
    case 0:
      return type0(durationInfo, startDate, endDate, true);
    case 1:
      return type1(durationInfo, startDate, endDate);
    case 2:
      return type2(durationInfo, startDate, endDate);
    default:
      return type2(durationInfo, startDate, endDate);
  }
}
