import React, { ReactElement } from 'react';
import style from './loadingDiamonds.module.css';

interface Props {
  title?: string;
  className?: string;
}

export default function LoadingDiamonds(props: Props): ReactElement {
  const { title, className } = props;
  return (
    <p className={`${style.loading} ${className}`}>
      <div className={style.loader}>
        <div className={style.one} />
        <div className={style.two} />
        <div className={style.three} />
      </div>
      {title}
    </p>
  );
}
LoadingDiamonds.defaultProps = {
  title: '',
  className: '',
};
