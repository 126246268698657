import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { backendResponse } from '../../types/backendResponse';
import {
  PassBuilderDateInputWithAugmentedDuration,
  PassBuilderDateInputWithAugmentedDurationProps,
} from './withAugmentedDuration';

const GET_DURATION_INFO = gql`
  query getDurationInfo($passInfoId: String!) {
    getPassInfo(passInfoId: $passInfoId) {
      success
      error
      data {
        passInfoId
        durationInfo {
          type
          duration1
        }
      }
    }
  }
`;
type GET_DURATION_INFO_VARS = {
  passInfoId: string;
};
type GET_DURATION_INFO_RES = {
  getPassInfo: backendResponse<{
    passInfoId: string;
    durationInfo: {
      type: number;
      duration1: number;
      duration2: number;
    };
  }>;
};

type PassBuilderDateInputWithFetchDurtionInfoProps = {
  passInfoId: string;
  loadingRender?: React.ReactElement;
} & Omit<PassBuilderDateInputWithAugmentedDurationProps, 'durationInfo'>;

export function PassBuilderDateInputWithFetchDurtionInfo(
  props: PassBuilderDateInputWithFetchDurtionInfoProps,
): React.ReactElement {
  const { passInfoId, loadingRender, ...passThroughProps } = props;
  const { data, loading } = useQuery<GET_DURATION_INFO_RES, GET_DURATION_INFO_VARS>(
    GET_DURATION_INFO,
    {
      variables: { passInfoId },
    },
  );

  if (loading) return loadingRender || <></>;
  if (!data?.getPassInfo.data?.durationInfo) return <></>;
  return (
    <PassBuilderDateInputWithAugmentedDuration
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...passThroughProps}
      durationInfo={data.getPassInfo.data.durationInfo}
    />
  );
}
PassBuilderDateInputWithFetchDurtionInfo.defaultProps = {
  loadingRender: undefined,
};
