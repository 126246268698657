import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import GenericAlert from '../../components/genericAlert/genericAlert';
import GenericButton from '../../components/genericButton/genericButton';
import LoadingDiamonds from '../../components/loadingDiamonds/loadingDiamonds';
import { PopUp } from '../../components/popUp/popUp';
import VehicleInfoForm, {
  vehicleFormData,
} from '../../components/vehicleInfoForm/vehicleInfoForm';
import PassInfo from '../../types/passInfo';
import style from './addEditVehicle.module.css';
import useAddEditVehicle from './useAddEditVehicle';

type VehicleFormPopupProps = {
  pass: PassInfo;
  error?: boolean | string;
  excludeFields?: string[];
  // setPass: React.Dispatch<SetStateAction<PassInfo>>;
};

export default function VehicleFormPopup(
  props: VehicleFormPopupProps,
): React.ReactElement {
  const { pass, error: fetchError, excludeFields } = props;
  const history = useHistory();
  const { loading, error: mutationError, submit } = useAddEditVehicle();
  const [data, setData] = useState<vehicleFormData>({
    ...pass,
    ...pass.vehicle,
    vehicle: null,
  });
  useEffect(() => {
    setData({
      ...pass,
      ...pass.vehicle,
      vehicle: null,
    });
  }, [pass, setData]);

  return (
    <PopUp
      close={() => history.goBack()}
      title={pass.vehicle.vehicleId ? 'Edit Vehicle' : 'Add Vehicle'}
    >
      <form
        className={style.wrapper}
        onSubmit={e => {
          e.preventDefault();
          submit(data)
            .then(() => {
              history.goBack();
            })
            .catch(err => {
              console.warn(err);
            });
        }}
      >
        {typeof fetchError === 'string' && (
          <GenericAlert color="red" title={fetchError} />
        )}
        <GenericAlert color="red" title={mutationError} hidden={!mutationError} />
        <VehicleInfoForm
          idx={0}
          title={pass.vehicle.vehicleId ? 'Edit Vehicle' : 'New Vehicle'}
          data={data}
          updateHandler={setData}
          excludeFields={excludeFields}
          removeHandler={() => {
            /* */
          }}
          allowRemove={false}
          allowCompleteLater={false}
        />
        {loading ? (
          <LoadingDiamonds />
        ) : (
          <GenericButton
            type="submit"
            title="Save"
            color="blue"
            size="medium"
            outline="none"
          />
        )}
      </form>
    </PopUp>
  );
}
VehicleFormPopup.defaultProps = {
  error: false,
};
