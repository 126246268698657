import { gql, useLazyQuery } from '@apollo/client';
import React, { ReactElement, useState } from 'react';
import { Redirect } from 'react-router-dom';
import ForgetPWPagesBackground from '../../components/forgetPWPagesBackground/forgetPWPagesBackground';
import GenericAlert from '../../components/genericAlert/genericAlert';
import GenericButton from '../../components/genericButton/genericButton';
import InputField from '../../components/inputField/inputField';
import style from './forgotPasswordEmail.module.css';

export const RESET_PASSWORD_EMAIL_QUERY = gql`
  query ResetPasswordEmail($email: String!) {
    sendResetCode(email: $email) {
      success
      error
    }
  }
`;

export default function ForgotPasswordEmail(): ReactElement {
  const [email, setEmail] = useState('');
  const [redirect, setRedirect] = useState<
    string | { pathname: string; state: { email: string } }
  >('');
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');

  const [doEmail] = useLazyQuery(RESET_PASSWORD_EMAIL_QUERY, {
    fetchPolicy: 'network-only',
    onError: e => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: d => {
      if (d.sendResetCode.success) {
        setRedirect({
          pathname: '/forgot-password/verification-code',
          state: {
            email,
          },
        });
      } else {
        setAlert(d.sendResetCode.error);
        setAlertColor('red');
      }
    },
  });

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <ForgetPWPagesBackground>
      <div>
        <h3 className={`${style.box__mainTitleRight} textColorMain `}>
          Forgot Password?
        </h3>
        <h5 className={`${style.box__subTitle} textColorDark `}>
          Enter your email address and we&apos;ll send you a link to reset your password
        </h5>
        <form
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (process?.env?.REACT_APP_DEBUG === 'true') {
              setRedirect('/forgot-password/verification-code');
            }
            doEmail({
              variables: {
                email,
              },
            });
          }}
        >
          {' '}
          <div className={style.alert}>
            <div className={style.alertBox}>
              <GenericAlert color={alertColor} title={alert} hidden={!alert} />
            </div>
          </div>
          <div className={style.box__inputLong}>
            <div className={style.box__inputField}>
              <InputField
                smallSize
                highlightOnFocus
                htmlFor="email-inpt"
                labelTitle="Email Address"
                inputType="email"
                inputValue={email}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
          </div>
          <div className={style.box__btnRight}>
            <div className={style.box__btnRightInnerBox}>
              <GenericButton size="medium" color="blue" title="Enter" type="submit" />
            </div>
          </div>
        </form>
      </div>
    </ForgetPWPagesBackground>
  );
}
