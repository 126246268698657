import React, { useEffect } from 'react';

const formatTel = (e: React.ChangeEvent<HTMLInputElement>): string => {
  try {
    console.log(e.nativeEvent);
    if ((e.nativeEvent as any).inputType.includes('insert')) {
      if (!/\d/.test((e.nativeEvent as any).data)) {
        return e.target.value.slice(0, e.target.value.length - 1);
      }
      switch (e.target.value.length) {
        case 3:
          return `(${e.target.value})`;
        case 4:
          return /\(\d{3}/.test(e.target.value) ? `${e.target.value})` : e.target.value;
        case 8:
          return `${e.target.value}-`;
        case 10:
          console.log('autofill detected');
          if (/\d{10}/.test(e.target.value)) {
            return `(${e.target.value.slice(0, 3)})${e.target.value.slice(
              3,
              6,
            )}-${e.target.value.slice(6, 10)}`;
          }
          return e.target.value;
        default:
          return e.target.value.slice(0, 13);
      }
    }
  } catch {
    //
  }
  return e.target.value;
};
const validateTel = (e: React.ChangeEvent<HTMLInputElement>): void => {
  if (/^\(\d{3}\)\d{3}-\d{4}$/.test(e.target.value)) {
    e.target.setCustomValidity('');
  } else {
    e.target.setCustomValidity(
      "Please provide a phone number in the format '(000)000-0000'",
    );
  }
  e.target.reportValidity();
};

const formatZip = (e: React.ChangeEvent<HTMLInputElement>): string => {
  try {
    if ((e.nativeEvent as any).inputType === 'insertText') {
      if (!/\d/.test((e.nativeEvent as any).data)) {
        return e.target.value.slice(0, e.target.value.length - 1);
      }
      return e.target.value.slice(0, 5);
    }
  } catch {
    //
  }
  return e.target.value;
};
const validateZip = (e: React.ChangeEvent<HTMLInputElement>): void => {
  if (/^\d{5}$/.test(e.target.value)) {
    e.target.setCustomValidity('');
  } else {
    e.target.setCustomValidity("Please provide a zip code in the format '00000'");
  }
  e.target.reportValidity();
};

export default function useFormatter(
  htmlFor: string,
  formatCode?: string,
  changeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void,
): (e: React.ChangeEvent<HTMLInputElement>) => void {
  // create event listener for after code is formatted
  useEffect(() => {
    // event handler
    const formattedChangeHandler: EventListener = (e: any): void => {
      if (changeHandler) {
        changeHandler(e as React.ChangeEvent<HTMLInputElement>);
      }
    };
    const inptElem = document.getElementById(htmlFor);
    // add listner
    inptElem?.addEventListener('formatted-change', formattedChangeHandler);
    // cleanup function
    return () =>
      inptElem?.removeEventListener('formatted-change', formattedChangeHandler);
  }, [htmlFor, changeHandler]);

  return (e: React.ChangeEvent<HTMLInputElement>): void => {
    switch (formatCode) {
      case 'tel':
        e.target.value = formatTel(e);
        break;
      case 'tel-strict':
        e.target.value = formatTel(e);
        validateTel(e);
        break;
      case 'zip':
        e.target.value = formatZip(e);
        break;
      case 'zip-strict':
        e.target.value = formatZip(e);
        validateZip(e);
        break;
      default:
        break;
    }
    e.target.dispatchEvent(new Event('formatted-change'));
  };
}
