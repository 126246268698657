import { applyMiddleware, createStore } from 'redux';
import { persistReducer, persistStore, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { getCommunityId } from '../communityConfigs/getCommunityId';
import { IDocumentConfig } from '../types/documentInfo';
import { PassType } from '../types/passType';

interface userType {
  userId: string;
  firstName: string;
  lastName: string;
  profileStatus: string;
  applicationId: string;
  email: string;
  roles: [string];
  ownerGateCode: string | null;
  fastPassUser: boolean;
}

export interface stateType {
  token: string | null;
  tokenDate: Date | null;
  user: userType | null;
  community: null | {
    communityId: string | null;
    name: string | null;
  };
  selectedPassTypes: PassType[];
  documentsInfoConfig: IDocumentConfig[];
  isRegistrationStepsComplete: boolean;
}

const initialState = {
  token: null,
  tokenDate: null,
  user: null,
  community: null,
  selectedPassTypes: [],
  documentsInfoConfig: [],
  isRegistrationStepsComplete: false,
};

const persistConfig = () => ({
  key: `${getCommunityId()}-user-store`,
  storage,
});

function userReducer(state: stateType = initialState, action: any): stateType {
  const res: stateType = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case 'user/login':
      res.token = action.payload.token;
      res.tokenDate = new Date();
      res.user = {
        userId: action.payload.user.userId,
        firstName: action.payload.user.firstName,
        lastName: action.payload.user.lastName,
        profileStatus: action.payload.user.profileStatus,
        applicationId: action.payload.user.applicationId,
        email: action.payload.user.email,
        roles: action.payload.user.roles,
        ownerGateCode: action.payload.user.ownerGateCode || null,
        fastPassUser: action.payload.user.fastPassUser || false,
      };
      if (!res.community) {
        res.community = {
          communityId: action.payload.user.community || getCommunityId() || null,
          name: null,
        };
      }
      return res;

    case 'user/logout':
      res.token = action.payload?.token || null;
      res.tokenDate = new Date();
      res.user = null;
      res.community = action.payload?.community || null;
      res.isRegistrationStepsComplete = false;
      return res;

    case 'user/updateGateCode':
      if (res.user) {
        res.user.ownerGateCode = action.payload.ownerGateCode;
      }
      return res;

    case 'community/setCommunity':
      if (res.user) return res;
      res.token = action.payload?.token || null;
      res.tokenDate = new Date();
      res.community = action.payload?.community || null;
      res.user = null;
      return res;
    case 'SAVE_SELECTED':
      res.selectedPassTypes = action.payload?.selectedPasses;
      res.documentsInfoConfig = action.payload?.documentsConfig;
      return res;
    case 'REMOVE_SELECTED':
      res.selectedPassTypes = [];
      // res.documentsInfoConfig = [];
      return res;
    case REHYDRATE:
      return action.payload;
    case 'UPDATE_PROFILE':
      if (res.user) {
        res.user.firstName =
          action.payload.firstName !== ''
            ? action.payload.firstName
            : res.user?.firstName;
        res.user.lastName =
          action.payload.lastName !== '' ? action.payload.lastName : res.user?.lastName;
      }
      return res;
    case 'registration_steps_status':
      res.isRegistrationStepsComplete = action.payload.complete || false;
      return res;
    default:
      return state;
  }
}

const persistedReducer = persistReducer(persistConfig(), userReducer);

// create store with middleware
export const store = createStore(
  persistedReducer,
  applyMiddleware(
    thunk,
    // logoutMiddleware,
  ),
);

export const persistor = persistStore(store);

export default store;

// ==== redux helpers ====
export const isLogedIn = (): boolean => Boolean(store.getState().user);
export const documentConfig = (): IDocumentConfig[] =>
  store.getState().documentsInfoConfig;
export const getUserDetails = () => {
  return store.getState().user;
};
export const getUserRegistrationSteps = (): boolean =>
  Boolean(store.getState().isRegistrationStepsComplete);
