import { useEffect, useState } from 'react';

// custom hook to test media query
export default function useMediaQuery(query: string): boolean {
  // state to store query results
  const [matches, setMatches] = useState<boolean>(false);
  // hook to keep state up to date
  useEffect(() => {
    // check initial value of media query
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    // handler for resize event
    const listener = () => setMatches(media.matches);
    // create listener for resize event
    window.addEventListener('resize', listener);
    // cleanup function returned
    return () => window.removeEventListener('resize', listener);
  }, [matches, query]);
  // return results
  return matches;
}
