import { useContext, useMemo } from 'react';
import { CommunityContext } from '../communityConfigs/communityContextProvider';
import { portalOptions } from '../types/portalOptions';

type baseT = {
  [attribute: string]: unknown;
};

// type for second level of object
type communityTextConfigOptions<T> = {
  [property in portalOptions | 'default']?: Partial<T>;
};
// type for default option of object
interface textsDefaultOption<T> extends communityTextConfigOptions<T> {
  default: T; // require only default option
}
// type for confing object
export type textOptionsType<T> = {
  default: textsDefaultOption<T>; // require only default option
  [communityId: string]: communityTextConfigOptions<T> | undefined;
};

// will get
export default function useTextSelection<T extends baseT>(
  textOptions: textOptionsType<T>,
  role: portalOptions | 'default' = 'default', // should use portal options
): T {
  const { communityId } = useContext(CommunityContext);
  // return memoized results
  return useMemo((): T => {
    // helper method to get the value of an attribute and falling back to defaults
    const getAttribute = (attr: keyof T): T[keyof T] => {
      // option 1 community and role speciffic val
      const sol1 = textOptions[communityId]?.[role]?.[attr];
      if (sol1) return sol1 as T[keyof T];
      // option 2 community default val
      const sol2 = textOptions[communityId]?.default?.[attr];
      if (sol2) return sol2 as T[keyof T];
      // option 3 default community, role speciffic val
      const sol3 = textOptions.default?.[role]?.[attr];
      if (sol3) return sol3 as T[keyof T];
      // option 4 defaut community and default role val
      return textOptions.default.default[attr];
    };

    // get array of keys from required default option and use it to create res object
    return Object.keys(textOptions.default.default).reduce(
      (o, key) => ({ ...o, [key]: getAttribute(key) }),
      textOptions.default.default,
    );
  }, [communityId, role, textOptions]);
}
