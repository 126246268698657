import React, { ReactElement } from 'react';
import style from '../../pages/privacyPolicy/privacyPolicy.module.css';

export default function TitledStection(props: {
  title: string;
  textContent?: Array<string>;
  subSections?: Array<{ h: string; b: string | ReactElement }>;
  children?: ReactElement | Array<ReactElement>;
  listContent?: Array<string>;
  listMapper?: (elem: string) => ReactElement;
}): ReactElement {
  const { title, textContent, subSections, children, listContent, listMapper } = props;
  return (
    <div>
      <h2 className={style.title}>{title}</h2>
      <p className={`${style.text} textColorSuperDark`}>
        {textContent?.map((el: string) => (
          <>
            <p className={`${style.textNoMargin} textColorSuperDark`}>{el}</p>
            <br />
          </>
        ))}
      </p>
      {subSections?.map((el: { h: string; b: string | ReactElement }) => (
        <>
          <h4 className={style.subtitle}>{el.h}</h4>
          <p className={`${style.text} textColorSuperDark`}>{el.b} </p>
        </>
      ))}
      {children}
      {listContent && listMapper ? (
        <ul className={style.listBox}>{listContent.map(listMapper)}</ul>
      ) : null}
    </div>
  );
}
TitledStection.defaultProps = {
  textContent: undefined,
  subSections: undefined,
  children: undefined,
  listContent: undefined,
  // eslint-disable-next-line react/display-name
  listMapper: (elem: string) => (
    <li className={`${style.listNoLine} textColorSuperDark`}>{elem}</li>
  ),
};
