import { gql } from '@apollo/client';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { getCommunityId } from '../communityConfigs/getCommunityId';
import { backendClient } from './BackendAPI';
import { stateType } from './store';

const GET_COMMUNITY_AUTH = gql`
  query GetCommunityToken($communityId: String!) {
    getCommunityToken(communityId: $communityId) {
      success
      error
      token
      data {
        communityId
        name
      }
    }
  }
`;

// thunk to trigger async actions on log out
export const logout: ThunkAction<
  Promise<unknown>,
  stateType,
  unknown,
  Action<'user/logout'>
> = async dispatch => {
  // use communityId currently in store
  const communityId = getCommunityId() || null;
  // clear cached db
  return (
    backendClient
      .clearStore()
      // then fetch new token
      .then(() =>
        backendClient
          .query({
            query: GET_COMMUNITY_AUTH,
            fetchPolicy: 'no-cache',
            variables: { communityId },
          })
          // then dispatch logout in redux
          .then(data =>
            dispatch({
              type: 'user/logout',
              payload: {
                community: { communityId },
                token: data.data.getCommunityToken.token,
              },
            }),
          )
          // if there is an error, log it and use default logout values, mostly null
          .catch(() =>
            dispatch({
              type: 'user/logout',
            }),
          ),
      )
  );
};

export const getAndSetToken = (
  newCommunityId = '',
): ThunkAction<Promise<string>, stateType, unknown, Action<'community/setCommunity'>> => {
  return async dispatch => {
    const communityId = newCommunityId || getCommunityId();
    // const { token, community } = store.getState();
    return (
      backendClient
        .query({
          query: GET_COMMUNITY_AUTH,
          fetchPolicy: 'no-cache',
          variables: { communityId },
        })
        // then dispatch action to set community
        .then(data => {
          dispatch({
            type: 'community/setCommunity',
            payload: {
              token: data.data.getCommunityToken.token,
              community: { communityId },
            },
          });
          return data.data.getCommunityToken.token;
        })
        // if failed to fetch data
        .catch(() => '')
    );
  };
};
