import Tippy from '@tippyjs/react';
import React, { ReactElement, useState } from 'react';
import style from './fileInputWithActions.module.css';

type propsType = {
  id: string;
  label?: string;
  changeHandler: (f?: File) => void;
  previousFile?: {
    name?: string;
    url?: string;
  };
};

export default function FileInputWithActions(props: propsType): React.ReactElement {
  const { id, label, changeHandler, previousFile } = props;
  const [file, setFile] = useState<File | undefined>();
  const [visible, setVisible] = useState<boolean>(false);

  const onClickHandler = (url: string) => {
    if (url) {
      window.open(url);
    }
  };

  const dropDownContent: React.ReactElement = (
    <ul className={style.menuContainer}>
      <li className={style.menuItem}>
        {/* this rule should be satisfied by the htmlFor attribute and text content */}
        {/* not sure why there is an error here */}
        {/* -eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={id} className={`${style.standardLabel} textColorSuperDark`}>
          Upload New File
        </label>
      </li>
      {file && (
        <li className={style.menuItem}>
          <button
            type="button"
            onClick={() => {
              const a = document.createElement('a');
              a.target = '_blank';
              a.href = URL.createObjectURL(file);
              a.click();
            }}
          >
            Preview File To Be Uploaded
          </button>
        </li>
      )}
      {previousFile?.url && (
        <li className={style.menuItem}>
          <button type="button" onClick={() => onClickHandler(`${previousFile.url}`)}>
            Download Saved File
          </button>
        </li>
      )}
      {/* {previousFile?.url && (
        <li className={style.menuItem}>
          <button
            type="button"
            onClick={() => {
              const a = document.createElement('a');
              a.download = previousFile.name || previousFile.url || '';
              a.href = previousFile.url || '';
              a.dataset.downloadurl = ['mimetype', a.download, a.href].join(':');
              a.click();
            }}
          >
            Download
          </button>
        </li>
      )} */}
      {file && (
        <li className={style.menuItem}>
          <button type="button" onClick={() => setFile(undefined)}>
            Reset
          </button>
        </li>
      )}
      {/* {(previousFile?.url && !file) && (
        <li className={style.menuItem}>
          <button
            type="button"
            onClick={() => setFile(new File([''], "Will Delete File"))}
          >
            Delete
          </button>
        </li>
      )} */}
    </ul>
  );

  let labelContent: ReactElement = (
    // this rule should be satisfied by the htmlFor attribute and text content
    // not sure why there is an error here
    // -eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label htmlFor={id} className={`${style.standardLabel} textColorSuperDark`}>
      {file ? file.name : 'Upload File...'}
    </label>
  );

  if (previousFile?.url && !file) {
    labelContent = (
      <a
        href={previousFile.url}
        target="_blank"
        className={style.anchor}
        rel="noreferrer"
      >
        {previousFile.name || 'Download Saved File'}
      </a>
    );
  }

  return (
    <div>
      <label htmlFor={id} className={`${style.standardLabel} textColorSuperDark`}>
        {label}
      </label>
      <input
        id={id}
        hidden
        className="textColorMedium"
        type="file"
        onChange={e => {
          const f = e.target.files?.[0];
          setFile(f);
          changeHandler(f);
          setVisible(false);
        }}
      />
      <div className={style.elemWrapper}>
        {labelContent}
        <Tippy
          content={dropDownContent}
          visible={visible}
          onClickOutside={() => setVisible(false)}
          allowHTML
          arrow={false}
          appendTo={document.body}
          interactive
          placement="bottom"
        >
          <button
            type="button"
            className={style.dropDownButton}
            onClick={() => setVisible(prev => !prev)}
          >
            Actions
          </button>
        </Tippy>
      </div>
    </div>
  );
}

FileInputWithActions.defaultProps = {
  label: undefined,
  previousFile: undefined,
};
