import { gql, useQuery } from '@apollo/client';
import { backendResponse } from '../types/backendResponse';

const GET_PASS_IMAGE = gql`
  query GetPassImage($passId: String!) {
    getPassImage(passId: $passId) {
      success
      error
      data {
        passId
        number
        externalCredentialNumber
        url
      }
    }
  }
`;
type GET_PASS_IMAGE_VARS = {
  passId: string;
};
type responseData = {
  passId: string;
  number: string;
  externalCredentialNumber: string;
  url: string;
};
type GET_PASS_IMAGE_RES = {
  getPassImage: backendResponse<responseData[]>;
};

type useGetPassImageReturn = {
  error?: string;
  data?: responseData;
  loading: boolean;
};

export default function useGetPassImage(
  passId: string,
  skip = false as boolean,
): useGetPassImageReturn {
  const { data, error, loading } = useQuery<GET_PASS_IMAGE_RES, GET_PASS_IMAGE_VARS>(
    GET_PASS_IMAGE,
    {
      skip,
      variables: {
        passId,
      },
      fetchPolicy: 'no-cache',
    },
  );

  return {
    data: data?.getPassImage.data?.[0] || undefined,
    loading,
    error:
      data?.getPassImage.error ||
      (error ? 'Something went wrong. Could not generate pass images.' : undefined),
  };
}
