import { MutationHookOptions, gql, useMutation } from '@apollo/client';
import { backendResponse } from '../../types/backendResponse';

export const SEND_LOGIN_LINK = gql`
  mutation ResendLink($userId: String, $registrationId: String) {
    sendLoginLink(userId: $userId, registrationId: $registrationId) {
      success
      error
      data {
        textSuccess
        emailSuccess
      }
    }
  }
`;

export type SEND_LOGIN_LINK_VARS = {
  userId?: string;
  registrationId?: string;
};
export type SEND_LOGIN_LINK_RES = {
  sendLoginLink: backendResponse<{
    textSuccess?: boolean;
    emailSuccess?: boolean;
  }>;
};

type optionsType = MutationHookOptions<SEND_LOGIN_LINK_RES, SEND_LOGIN_LINK_VARS>;

export const useSendLoginLink = (options: optionsType) =>
  useMutation<SEND_LOGIN_LINK_RES, SEND_LOGIN_LINK_VARS>(SEND_LOGIN_LINK, {
    fetchPolicy: 'no-cache',
    ...options,
  });
