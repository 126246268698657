import React, { ReactElement, useEffect } from 'react';
import { ReactComponent as CloseImg } from '../../assets/close.svg';
import GenericButton from '../genericButton/genericButton';
import style from './popUp.module.css';

type PopUpProps = {
  children: any;
  title?: string;
  close: () => void;
  isOpen?: boolean;
  largeSize?: boolean;
};

export const PopUp = (props: PopUpProps): ReactElement => {
  const { title, isOpen, close, children, largeSize } = props;

  let box;
  if (largeSize) {
    box = style.boxLarge;
  } else {
    box = style.box;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isOpen) {
    return (
      <div className={`${style.greyBackGround} darkGrey `}>
        <div className={`${box} white `}>
          <div className={`${style.box__header} mainColor `}>
            <h2 className={`${style.box__title} textColorWhite `}>{title}</h2>
            <div className={style.btn}>
              {/* TODO Show exit button */}
              <GenericButton
                // icon="exit"
                icon={(className: string) => <CloseImg className={`${className}`} />}
                title=""
                clickHandler={() => close()}
              />
            </div>
          </div>
          <div className={style.itemBox}>{children}</div>
        </div>
      </div>
    );
  }
  return <></>;
};
PopUp.defaultProps = {
  title: '',
  isOpen: true,
  largeSize: false,
};
