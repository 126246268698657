import { ReactElement } from 'react';
import style from './genericAlert.module.css';

export const GenericAlert = (props: {
  color?: 'red' | 'green' | 'yellow';
  children?: string;
  title?: string;
  hidden?: boolean;
  className?: string;
}): ReactElement => {
  const { color, children, hidden, title, className } = props;

  const containerStyle = {
    backgroundColor: '',
    border: '',
    color: '',
  };

  switch (color) {
    case 'red':
      containerStyle.backgroundColor = '#FFF2F0';
      containerStyle.border = '1px solid #d9363e';
      containerStyle.color = '#d9363e';
      break;
    case 'green':
      containerStyle.backgroundColor = '#F6FFED';
      containerStyle.border = '1px solid #73d13d';
      containerStyle.color = '#389e0d';
      break;
    case 'yellow':
      containerStyle.backgroundColor = '#FFFFE6';
      containerStyle.border = '1px solid #e6e600';
      containerStyle.color = '#b3b300';
      break;
    default:
      containerStyle.backgroundColor = 'var(--alertColor)';
      containerStyle.border = '1px solid #d9363e';
      containerStyle.color = '#d9363e';
      break;
  }

  if (hidden) {
    return <></>;
  }
  return (
    <div style={containerStyle} className={`${style.alertLine}  ${className}`}>
      <h5 className={style.title}>{children || title}</h5>
    </div>
  );
};
GenericAlert.defaultProps = {
  color: 'none',
  children: '',
  title: '',
  hidden: false,
  className: '',
};

export default GenericAlert;
