import React, { useEffect } from 'react';
import { AugmentedDurationInfo } from '../../utilities/augmentDuration';
import { formatDateForInput, formatDateForInputUTC } from '../../utilities/formatDate';
import style from './passNuilderDateInput.module.css';

type minPassType = {
  startDate: string;
  endDate: string;
};
export type PassBuilderDateInputProps = {
  id: string;
  augmentedDurationInfo: AugmentedDurationInfo;
  setter: (obj: Partial<minPassType>) => void;
  autoSetDefault?: boolean | ((cfd: AugmentedDurationInfo) => boolean);
  className?: string;
  disableDefaultStyle?: boolean;
  disabled?: boolean;
  required?: boolean;
};
function protectedFormatForInput(val?: Date): string {
  return val ? formatDateForInputUTC(val) : '';
}

export function PassBuilderDateInput(
  props: PassBuilderDateInputProps,
): React.ReactElement {
  const {
    id,
    className,
    disableDefaultStyle,
    setter,
    autoSetDefault,
    disabled,
    required,
    augmentedDurationInfo: data,
  } = props;

  useEffect(() => {
    if (!autoSetDefault) return;
    if (typeof autoSetDefault === 'function' && !autoSetDefault(data)) return;
    if (!data.startDate) {
      setter({ startDate: formatDateForInputUTC(data.minStartDate) });
    }
    if (!data.endDate) {
      setter({ endDate: formatDateForInputUTC(data.minEndDate) });
    }
  }, [data.startDate, data.endDate, data.minStartDate, data.minEndDate]);

  return (
    <div
      className={`${disableDefaultStyle ? '' : style.defaultStyle} ${className}`}
      id={`${id}-wrapper`}
    >
      <label htmlFor={`${id}-start-date`}>
        Start Date {required ? '*' : ''}
        <input
          type="date"
          id={`${id}-start-date`}
          disabled={disabled || !data.editable}
          required={required}
          value={protectedFormatForInput(data.startDate)}
          max={protectedFormatForInput(data.maxStartDate)}
          min={formatDateForInput(data.minStartDate)}
          onChange={e =>
            setter({
              startDate: e.target.value,
            })
          }
        />
      </label>
      <label htmlFor={`${id}-end-date`}>
        End Date {required ? '*' : ''}
        <input
          type="date"
          id={`${id}-end-date`}
          disabled={disabled || !data.editable}
          required={required}
          value={protectedFormatForInput(data.endDate)}
          max={protectedFormatForInput(data.maxEndDate)}
          min={formatDateForInput(data.minEndDate)}
          onChange={e =>
            setter({
              endDate: e.target.value,
            })
          }
        />
      </label>
    </div>
  );
}
PassBuilderDateInput.defaultProps = {
  disabled: false,
  required: false,
  disableDefaultStyle: false,
  autoSetDefault: false,
};
