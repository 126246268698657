import { gql } from '@apollo/client';
import Tippy from '@tippyjs/react';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { ReactElement, useContext, useRef, useState, createContext } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ActionImg } from '../../../assets/actionIcon.svg';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import VehicleInfo from '../../../common_lib_front/types/vehicleInfo';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';
import style from './vehicles.module.css';

const DELETE_VEHICLE = gql`
  mutation Mutation($vehicleId: String!) {
    deleteCompanyVehicle(vehicleId: $vehicleId) {
      success
      error
    }
  }
`;
type DELETE_VEHICLE_VARS = {
  vehicleId: string;
};
type DELETE_VEHICLE_RES = {
  deleteCompanyVehicle: backendResponse<undefined>;
};

const AddEditLinkContext = createContext<{ addEditLink: string }>({ addEditLink: '' });

type actionsPopupProps = {
  data?: VehicleInfo;
};

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
  flex: 1,
  wrapHeaderText: true,
};
function ActionsPopup(props: actionsPopupProps): ReactElement {
  const { data } = props;
  const { addEditLink } = useContext(AddEditLinkContext);
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <Tippy
      // ref={tippyRef}
      content={
        <div className={style.menuContainer}>
          <Link
            to={`${addEditLink}${data?.vehicleId ? `/${data.vehicleId}` : ''}`}
            className={style.menuItem}
          >
            <span className={style.itemText}>Edit</span>
          </Link>
          {data?.vehicleId ? (
            <button
              className={style.menuItem}
              onClick={e => {
                if (!window.confirm('Are you sure you want to delete this vehicle?'))
                  return;
                backendClient
                  .mutate<DELETE_VEHICLE_RES, DELETE_VEHICLE_VARS>({
                    mutation: DELETE_VEHICLE,
                    variables: {
                      vehicleId: data.vehicleId,
                    },
                  })
                  .then(res => {
                    if (res.data?.deleteCompanyVehicle.error) {
                      window.alert(res.data.deleteCompanyVehicle.error);
                    }
                    e.target.dispatchEvent(
                      new CustomEvent('vehicle-data-invalidation', {
                        bubbles: true,
                      }),
                    );
                  })
                  .catch(() => {
                    window.alert(
                      'Something went wrong. Changes may not have been saved.',
                    );
                  });
              }}
            >
              Delete
            </button>
          ) : null}
        </div>
      }
      visible={visible}
      onClickOutside={() => setVisible(false)}
      allowHTML
      arrow={false}
      appendTo={document.body}
      interactive
      placement="right"
    >
      <div className={style.actionBox}>
        <button className={style.actionBtn} onClick={() => setVisible(prev => !prev)}>
          <p>Actions</p>
          <ActionImg />
        </button>
      </div>
    </Tippy>
  );
}

const columnConfig: ColDef<VehicleInfo>[] = [
  {
    headerName: '',
    field: 'actions',
    pinned: true,
    cellRenderer: ActionsPopup,
    maxWidth: 100,
  },
  {
    field: 'make',
    editable: false,
    sortable: true,
    filter: true,
    minWidth: 150,
  },
  {
    field: 'type',
    editable: false,
    sortable: true,
    filter: true,
    minWidth: 180,
  },
  {
    field: 'model',
    editable: false,
    sortable: true,
    filter: true,
    minWidth: 180,
  },
  {
    field: 'year',
    editable: false,
    sortable: true,
    filter: true,
    minWidth: 180,
  },
  {
    field: 'plateNumber',
    editable: false,
    sortable: true,
    filter: true,
    minWidth: 180,
  },
  {
    field: 'licensePlateState',
    headerName: 'Plate State',
    editable: false,
    sortable: true,
    filter: true,
    minWidth: 180,
  },
  {
    field: 'fleetNumber',
    editable: false,
    sortable: true,
    filter: true,
    width: 150,
  },
];

type propsType = {
  data: VehicleInfo[];
  addEditLink: string;
  excludeFields?: string[];
};
export default function VehicleListAGGrid(props: propsType): React.ReactElement {
  const { data, addEditLink, excludeFields } = props;
  const grid = useRef<AgGridReact>(null);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  return (
    <AddEditLinkContext.Provider value={{ addEditLink }}>
      <div>
        <div className={style.topLine}>
          <button
            className={style.btnExport}
            onClick={() => {
              grid.current?.api.exportDataAsCsv();
            }}
          >
            Export CSV
          </button>
        </div>
        <div className={style.paginationBox}>
          <label htmlFor="page-num-inpt">
            Show
            <select
              className={style.selectBox}
              value={itemsPerPage}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setItemsPerPage(Number.parseInt(e.target.value, 10));
              }}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            Per Page
          </label>
        </div>
        <div className={style.tableBox}>
          <div
            className="ag-theme-alpine"
            style={{
              height: '60vh',
            }}
          >
            <AgGridReact
              ref={grid}
              defaultColDef={defaultColDef}
              columnDefs={
                excludeFields
                  ? columnConfig.filter(
                      c => !!c.field && !excludeFields.includes(c.field), // eslint-disable-line indent
                    ) // eslint-disable-line indent
                  : columnConfig
              }
              rowData={data}
              enableCellTextSelection
              ensureDomOrder
              animateRows
              pagination
              paginationPageSize={itemsPerPage}
              // paginationAutoPageSize
            />
          </div>
        </div>
      </div>
    </AddEditLinkContext.Provider>
  );
}
