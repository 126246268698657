export interface UserInfo {
  address: string;
  city: string;
  communityCode: string;
  companyName: string;
  country: string;
  email: string;
  emergencyContact: string;
  firstName: string;
  lastName: string;
  pdkId?: number;
  phoneNumber: string;
  roles: Array<string>;
  state: string;
  status: string;
  userId: string;
  zipCode: string;
}

export function newUserInfo(data: Partial<UserInfo> = {}): UserInfo {
  return {
    address: data?.address || '',
    city: data?.city || '',
    communityCode: data?.communityCode || '',
    companyName: data?.companyName || '',
    country: data?.country || '',
    email: data?.email || '',
    emergencyContact: data?.emergencyContact || '',
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    pdkId: data?.pdkId,
    phoneNumber: data?.phoneNumber || '',
    roles: data?.roles || [],
    state: data?.state || '',
    status: data?.status || '',
    userId: data?.userId || '',
    zipCode: data?.zipCode || '',
  };
}

export default UserInfo;
