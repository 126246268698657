interface IGuestConfig {
  selfRegistration: boolean;
}

interface IResidentConfig {
  enabled: boolean;
  registration: null | {
    educationStepEnabled: boolean;
    documentStepEnabled: boolean;
  };
  residentPasses: null | {
    enabled: boolean;
  };
  inviteVendor: null | {
    enabled: boolean;
  };
  familyAndFriendsPasses: null | {
    enabled: boolean;
    identificationRequired: boolean;
  };
  myPasses: boolean;
  gateCode: boolean;
  profile: boolean;
}

interface IHostConfig {
  enabled: boolean;
  validLength: number;
  inviteGuest: null | {
    enabled: boolean;
    hostMustPay: boolean;
    multiplePasses: boolean;
  };
  initialRegistration: null | {
    educationStepEnabled: boolean;
    documentStepEnabled: boolean;
    rentalRegistrationStepEnabled: boolean;
  };
  ownerPinEnabled: boolean;
  rentalRegistrationLabel: string;
}

interface IVendorConfig {
  placeholder: boolean;
  enabled: boolean;
  businessTypeRequired: boolean;
  hideEmployeeMenu?: boolean;
  hideVehicleMenu?: boolean;
  initialRegistration: null | {
    educationByBusinessType: boolean;
    autoApprove: boolean;
  };
}

interface IAdminConfig {
  showOtherPortalLinks: boolean;
}

interface IAutoGateAttendantConfig {
  placeholder: boolean;
}

export interface ICommunityConfig {
  guest: IGuestConfig | null;
  resident: IResidentConfig | null;
  host: IHostConfig | null;
  vendor: IVendorConfig | null;
  admin: IAdminConfig | null;
  autoGateAttendant: IAutoGateAttendantConfig | null;
}

export class CommunityConfig implements ICommunityConfig {
  guest: IGuestConfig | null;
  resident: IResidentConfig | null;
  host: IHostConfig | null;
  vendor: IVendorConfig | null;
  admin: IAdminConfig | null;
  autoGateAttendant: IAutoGateAttendantConfig | null;

  constructor(data: Partial<ICommunityConfig>) {
    this.guest = CommunityConfig.makeGuestConfig(data.guest);
    this.resident = CommunityConfig.makeResidentConfig(data.resident);
    this.host = CommunityConfig.makeHostConfig(data.host);
    this.vendor = CommunityConfig.makeVendorConfig(data.vendor);
    this.admin = CommunityConfig.makeAdminConfig(data.admin);
    this.autoGateAttendant = CommunityConfig.makeAutoGateConfig(data.autoGateAttendant);
  }

  // default guest config constructor
  static makeGuestConfig(data?: Partial<IGuestConfig> | null): IGuestConfig | null {
    if (!data) return null;
    return {
      selfRegistration: this.typeAssertion(data.selfRegistration, 'boolean', true),
    };
  }

  static makeResidentConfig(
    data?: Partial<IResidentConfig> | null,
  ): IResidentConfig | null {
    if (!data) return null;
    return {
      enabled: this.typeAssertion(data.enabled, 'boolean', false),
      registration: this.nullCheck(data.registration, {
        educationStepEnabled: this.typeAssertion(
          data.registration?.educationStepEnabled,
          'boolean',
          false,
        ),
        documentStepEnabled: this.typeAssertion(
          data.registration?.documentStepEnabled,
          'boolean',
          false,
        ),
      }),
      residentPasses: this.nullCheck(data.residentPasses, {
        enabled: this.typeAssertion(data.residentPasses?.enabled, 'boolean', false),
      }),
      inviteVendor: this.nullCheck(data.inviteVendor, {
        enabled: this.typeAssertion(data.inviteVendor?.enabled, 'boolean', false),
      }),
      familyAndFriendsPasses: this.nullCheck(data.familyAndFriendsPasses, {
        enabled: this.typeAssertion(
          data.familyAndFriendsPasses?.enabled,
          'boolean',
          false,
        ),
        identificationRequired: this.typeAssertion(
          data.familyAndFriendsPasses?.identificationRequired,
          'boolean',
          false,
        ),
      }),
      myPasses: this.typeAssertion(data.myPasses, 'boolean', false),
      gateCode: this.typeAssertion(data.gateCode, 'boolean', false),
      profile: this.typeAssertion(data.profile, 'boolean', true),
    };
  }

  static makeHostConfig(data?: Partial<IHostConfig> | null): IHostConfig | null {
    if (!data) return null;
    return {
      enabled: this.typeAssertion(data.enabled, 'boolean', false),
      validLength: this.typeAssertion(data.validLength, 'number', 0),
      inviteGuest: this.nullCheck(data.inviteGuest, {
        enabled: this.typeAssertion(data.inviteGuest?.enabled, 'boolean', false),
        hostMustPay: this.typeAssertion(data.inviteGuest?.hostMustPay, 'boolean', false),
        multiplePasses: this.typeAssertion(
          data.inviteGuest?.multiplePasses,
          'boolean',
          false,
        ),
      }),
      initialRegistration: this.nullCheck(data.initialRegistration, {
        educationStepEnabled: this.typeAssertion(
          data.initialRegistration?.educationStepEnabled,
          'boolean',
          false,
        ),
        documentStepEnabled: this.typeAssertion(
          data.initialRegistration?.documentStepEnabled,
          'boolean',
          false,
        ),
        rentalRegistrationStepEnabled: this.typeAssertion(
          data.initialRegistration?.rentalRegistrationStepEnabled,
          'boolean',
          false,
        ),
      }),
      ownerPinEnabled: this.typeAssertion(data.ownerPinEnabled, 'boolean', false),
      rentalRegistrationLabel: this.typeAssertion(
        data.rentalRegistrationLabel,
        'string',
        '',
      ),
    };
  }

  static makeVendorConfig(data?: Partial<IVendorConfig> | null): IVendorConfig | null {
    if (!data) return null;
    return {
      placeholder: false,
      enabled: this.typeAssertion(data.enabled, 'boolean', false),
      businessTypeRequired: this.typeAssertion(
        data.businessTypeRequired,
        'boolean',
        false,
      ),
      hideEmployeeMenu: this.typeAssertion(data.hideEmployeeMenu, 'boolean', false),
      hideVehicleMenu: this.typeAssertion(data.hideVehicleMenu, 'boolean', false),
      initialRegistration: this.nullCheck(data.initialRegistration, {
        educationByBusinessType: this.typeAssertion(
          data.initialRegistration?.educationByBusinessType,
          'boolean',
          false,
        ),
        autoApprove: this.typeAssertion(
          data.initialRegistration?.autoApprove,
          'boolean',
          false,
        ),
      }),
    };
  }

  static makeAdminConfig(data?: Partial<IAdminConfig> | null): IAdminConfig | null {
    if (!data) return null;
    return {
      showOtherPortalLinks: this.typeAssertion(
        data.showOtherPortalLinks,
        'boolean',
        true,
      ),
    };
  }

  static makeAutoGateConfig(
    data?: Partial<IAutoGateAttendantConfig> | null,
  ): IAutoGateAttendantConfig | null {
    if (!data) return null;
    return {
      placeholder: false,
    };
  }

  static typeAssertion<T>(val: unknown, type: string, backup: T): T {
    if (typeof val === type) {
      return val as T;
    }
    return backup;
  }

  static nullCheck<T>(initial: unknown, val: T): null | T {
    if (!initial && typeof initial !== 'boolean') return null;
    return val;
  }
}
