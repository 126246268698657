import { useMemo } from 'react';
import {
  AugmentedDurationInfo,
  DurationInfo,
  augmentDurationInfo,
} from '../utilities/augmentDuration';

export default function useAugmentedDurationInfo(
  durationInfo: DurationInfo,
  startDate?: string,
  endDate?: string,
): AugmentedDurationInfo {
  return useMemo(
    () => augmentDurationInfo(durationInfo, startDate, endDate),
    // re-run on data change from within object, not on object id change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      durationInfo.type,
      durationInfo.duration1,
      durationInfo.duration2,
      startDate,
      endDate,
    ],
  );
}
