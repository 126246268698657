import { formatDateForInput } from '../utilities/formatDate';

export type RentalInfo = {
  communityRentalId: string;
  address: string;
  arrivalDate: string;
  departureDate: string;
  numberGuests: number;
  numberPets: number;
  guestCanEdit: boolean;
  [key: string]: any;
};

export function newRentalInfo(data: Partial<RentalInfo> = {}): RentalInfo {
  return {
    communityRentalId: data?.communityRentalId || '',
    address: data?.address || '',
    arrivalDate: data?.arrivalDate ? formatDateForInput(data.arrivalDate) : '',
    departureDate: data?.departureDate ? formatDateForInput(data.departureDate) : '',
    numberGuests: data?.numberGuests || 1,
    numberPets: data?.numberPets || 0,
    guestCanEdit: typeof data?.guestCanEdit === 'boolean' ? data.guestCanEdit : true,
  };
}

export default RentalInfo;
