// formats a date to be displayed to user
export function formatDate(d?: Date): string {
  const dd = String(d?.getUTCDate() || '--').padStart(2, '0');
  const mm = String(d ? d.getUTCMonth() + 1 || '--' : '--').padStart(2, '0');
  const yyyy = String(d?.getUTCFullYear() || '----');
  return `${mm}/${dd}/${yyyy}`;
}

// formats date for use by html input
export function formatDateForInput(d?: Date | string): string {
  let date = typeof d === 'object' ? d : new Date();
  if (typeof date === null) {
    console.trace('Null date. This is an error that needs to be fixed');
    date = new Date();
  }
  if (typeof d === 'string') {
    const tmp = new Date(d.substring(0, 23));
    if (!Number.isNaN(tmp.getDate())) {
      date = tmp;
    }
  }
  // NOTE(Parker): This is not a totally perfect solution because it uses
  // the user's local timezone instead of a timezone appropriate for the community
  // I propose moving forward with this for now as the only way this
  // becomes problematic is if a user were trying to use the system from Europe
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}

export function formatDateForInputUTC(d?: Date | string): string {
  let date = typeof d === 'object' ? d : new Date();
  if (typeof date === null) {
    console.trace('Null date. This is an error that needs to be fixed');
    date = new Date();
  }
  if (typeof d === 'string') {
    const tmp = new Date(d);
    if (!Number.isNaN(tmp.getDate())) {
      date = tmp;
    }
  }
  return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getUTCDate().toString().padStart(2, '0')}`;
}

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
// Format a date with day name
export function formatDayWithDayName(d?: Date | string | null): string {
  if (!d) {
    return '';
  }
  const date = typeof d === 'object' ? d : new Date(d || '');
  if (typeof date.getUTCDay !== 'function') {
    return '';
  }
  return `${days[date.getUTCDay()]}, ${
    months[date.getUTCMonth()]
  } ${date.getUTCDate()}, ${date.getUTCFullYear()}`;
}
export function formatDateWithMonthName(d?: Date | string | null): string {
  if (!d) {
    return '';
  }
  const date = typeof d === 'object' ? d : new Date(d || '');
  return `${date.getUTCDate()} ${months[date.getUTCMonth()]} ${date.getUTCFullYear()}`;
}
export function addDays(d: Date, num: number): Date {
  const result = new Date(d);
  result.setDate(result.getDate() + num);
  return result;
}

// Date the number of days between 2 days
export function dateDiffInDays(a: Date, b: Date): number {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / MS_PER_DAY);
}

export function convertUTCToLocalDate(date: Date | null | undefined): Date | null {
  if (!date) {
    return null;
  }
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
}
export function convertLocalToUTCDate(date: Date | null | undefined): Date | null {
  if (!date) {
    return null;
  }
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
}
