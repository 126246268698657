import RentalInfo, { newRentalInfo } from './rentalInfo';
import VehicleInfo, { newVehicleInfo } from './vehicleInfo';

export const PaymentStatuses = {
  paid: 'paid',
  unpaid: 'unpaid',
  noCharge: 'no-charge',
  refunded: 'refunded',
} as const;

export type PassTypeInfo = {
  passInfoId: string;
  portal: string;
  passApprovalRequire?: boolean;
  [key: string]: any;
};

export type ApplicationInfo = {
  type: string;
  registrationId: string;
  status: string;
  [key: string]: any;
};

export type PassStatuses =
  | 'active'
  | 'inactive'
  | 'incomplete'
  | 'incomplete-rental-car'
  | 'expired'
  | 'deleted';
export type PassInfo = {
  passId: string;
  passInfoId: string;
  passInfo: PassTypeInfo | null;
  userId: string;
  vehicle: VehicleInfo;
  startDate: string;
  endDate: string;
  price: number;
  number: string;
  status: PassStatuses;
  passType: string;
  registrationId: string;
  paid: string;
  barCode: string;
  addons: string[];
  url: string;
  paymentType: string;
  communityRental?: RentalInfo;
  externalCredentialNumber?: string;
  applicationData?: ApplicationInfo | null;
  [key: string]: any;
};

export const passStatusMapTitles: { [key: string]: string } = {
  active: 'Activated',
  inactive: 'Ready to Scan',
  incomplete: 'Incomplete',
  'incomplete-rental-car': 'Rental Incomplete',
  expired: 'Expired',
  refunded: 'Refunded',
  'temporary-issued': 'Temporary Pass',
  deleted: 'Deleted',
};

export const paymentStatusMapTitles: { [key: string]: string } = {
  paid: 'Paid',
  unpaid: 'Not Paid',
  refunded: 'Refunded',
  'no-charge': 'No Charge',
  'ach-failed': 'ACH Failed',
  'ach-pending': 'ACH Pending',
};

export function newPassInfo(data: Partial<PassInfo> = {}): PassInfo {
  return {
    passId: data?.passId || '',
    passInfoId: data?.passInfoId || '',
    userId: data?.userId || '',
    vehicle: newVehicleInfo(data?.vehicle || undefined),
    passInfo: data?.passInfo || null,
    startDate: data?.startDate || '',
    endDate: data?.validThrough || data?.endDate || '',
    price: data?.price || 0,
    number: data?.number || '',
    status: data?.status || 'incomplete',
    passType: data?.passType || '',
    registrationId: data?.registrationId || '',
    paid: data?.paid || '',
    barCode: data?.barCode || '',
    addons: data?.addons || [],
    url: data?.url || '',
    sharedWith: data?.sharedWith || [],
    paymentType: data?.paymentType || '',
    communityRental: newRentalInfo(data?.communityRental || undefined),
    externalCredentialNumber: data?.externalCredentialNumber || '',
    applicationData: data?.applicationData || null,
  };
}

export default PassInfo;
