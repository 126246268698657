import React from 'react';
import useAugmentedDurationInfo from '../../hooks/useAugmentedDuration';
import { DurationInfo } from '../../utilities/augmentDuration';
import { PassBuilderDateInput, PassBuilderDateInputProps } from './passBuilderDateInput';

export type PassBuilderDateInputWithAugmentedDurationProps = {
  startDate: string;
  endDate: string;
  durationInfo: DurationInfo;
} & Omit<PassBuilderDateInputProps, 'augmentedDurationInfo'>;

export function PassBuilderDateInputWithAugmentedDuration(
  props: PassBuilderDateInputWithAugmentedDurationProps,
): React.ReactElement {
  const { startDate, endDate, durationInfo, ...otherProps } = props;
  const config = useAugmentedDurationInfo(durationInfo, startDate, endDate);
  return (
    <PassBuilderDateInput
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...otherProps}
      augmentedDurationInfo={config}
    />
  );
}
