import { portalOptions } from '../types/portalOptions';
// object to hold application urls for consistency
// urls must not end with a slash
// params added later to these urls will add prefixing slashes

// getPortalUrlRelative requires `dev` for localhost and `<portal>Staging`
// as keys for staging urls sets

type urlSet = {
  patern: RegExp;
  front: string;
  back: string;
};

function urlRegex(domain: string): RegExp {
  // eslint-disable-next-line no-useless-escape
  const urlScheme = '^https?://(www.)?';
  return new RegExp(`${urlScheme}${domain}`);
}

export const symlivUrls: Record<
  portalOptions | 'dev' | `${portalOptions}Staging`,
  urlSet
> = {
  dev: {
    patern: urlRegex('localhost'),
    front: 'localhost:3000',
    back: 'http://localhost:5678/graphql',
  },
  guest: {
    patern: urlRegex('guest.symliv.com'),
    front: 'https://guest.symliv.com',
    back: 'https://u7a7crfzuz.us-east-2.awsapprunner.com/graphql',
  },
  resident: {
    patern: urlRegex('resident.symliv.com'),
    front: 'https://resident.symliv.com',
    back: 'https://6qsbmjdazr.us-east-2.awsapprunner.com/graphql',
  },
  gateAttendant: {
    patern: urlRegex('gate.symliv.com'),
    front: 'https://gate.symliv.com/',
    back: 'https://vxtdvmqsim.us-east-2.awsapprunner.com/graphql',
  },
  vendor: {
    patern: urlRegex('vendor.symliv.com'),
    front: 'https://vendor.symliv.com',
    // back: 'https://api.vendor.symliv.com/graphql', // used in beta system
    back: 'https://fycdgjpdmg.us-east-2.awsapprunner.com/graphql',
  },
  admin: {
    patern: urlRegex('admin.symliv.com'),
    front: 'https://admin.symliv.com',
    back: 'https://8ftizrpawz.us-east-2.awsapprunner.com/graphql',
  },
  symliv: {
    patern: urlRegex('mgxbzrtwvq.us-east-2.awsapprunner.com'),
    front: 'https://mgxbzrtwvq.us-east-2.awsapprunner.com',
    back: 'https://tz22wf8pxk.us-east-2.awsapprunner.com/graphql',
  },
  guestStaging: {
    patern: urlRegex('staging.guest.symliv.com'),
    front: 'https://staging.guest.symliv.com',
    back: 'https://staging.api.guest.symliv.com/graphql',
  },
  residentStaging: {
    patern: urlRegex('staging.resident.symliv.com'),
    front: 'https://staging.resident.symliv.com',
    back: 'https://staging.api.resident.symliv.com/graphql',
  },
  gateAttendantStaging: {
    patern: urlRegex('staging.gate.symliv.com'),
    front: 'https://staging.gate.symliv.com/',
    back: 'https://staging.api.gate.symliv.com/graphql',
  },
  vendorStaging: {
    patern: urlRegex('staging.vendor.symliv.com'),
    front: 'https://staging.vendor.symliv.com',
    back: 'https://staging.api.vendor.symliv.com/graphql',
  },
  adminStaging: {
    patern: urlRegex('staging.admin.symliv.com'),
    front: 'https://staging.admin.symliv.com',
    back: 'https://staging.api.admin.symliv.com/graphql',
  },
  symlivStaging: {
    patern: urlRegex('staging.symliv.symliv.com'),
    front: 'https://staging.symliv.symliv.com',
    back: 'https://staging.api.symliv.symliv.com/graphql',
  },
};
export default symlivUrls;

export function getCurrentPortalKey(): keyof typeof symlivUrls {
  return (
    (Object.keys(symlivUrls) as Array<keyof typeof symlivUrls>)
      // .find((key) => symlivUrls[key].front.includes(window.location.hostname))
      .find(key => symlivUrls[key].patern.test(window.location.href)) || 'dev'
  );
}

export function getCurrentPortalUrls(): urlSet {
  return (
    Object.values(symlivUrls)
      // .find((obj: urlSet) => obj.front.includes(window.location.hostname))
      .find((obj: urlSet) => obj.patern.test(window.location.href)) || symlivUrls.dev
  );
}

export function getUrlsDynamic(portal: portalOptions): urlSet {
  const currentKey = getCurrentPortalKey();
  // for staging and dev servers return staging url sets
  if (currentKey.includes('dev') || currentKey.includes('Staging')) {
    // return urls at generated key, with current key as backup to prevent crash
    return (
      symlivUrls[`${portal}Staging` as keyof typeof symlivUrls] || symlivUrls[currentKey]
    );
  }
  // else return prod url
  return symlivUrls[portal];
}
