import React, { useCallback, useEffect, useState } from 'react';
import useContainerBlur from '../../utilities/useContainerBlur';
import { SearchSelectorProps } from './searchSelector';

export default function useSearchSelector(props: SearchSelectorProps): {
  open: boolean;
  setOpen: (val: boolean) => void;
  optionsList: Array<string>;
  parentId: string;
  showWarning: boolean;
  checkWarning: () => void;
  optionSelectHandler: (val: string) => void;
  innerChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
} {
  const { htmlFor, value, changeHandler, fetch, selectHandler, addressValues, required } =
    props;

  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [optionsList, setOptionsList] = useState<Array<string>>([]);
  const [isValidated, setIsValidated] = useState<boolean>(false);
  // setting validity to prevent form submission
  useEffect(() => {
    const elem = document.getElementById(htmlFor);
    if (elem instanceof HTMLInputElement) {
      const validity =
        !required || isValidated ? '' : 'Select exact match from dropdown options.';
      elem.setCustomValidity(validity);
      // elem.reportValidity();
    }
  }, [isValidated, required, htmlFor]);
  const {
    open,
    setOpen,
    containerId: parentId,
  } = useContainerBlur(`container-parent-${htmlFor}`);

  const optionSelectHandler = (val: string) => {
    console.log('selected');
    changeHandler(val);
    setOpen(false);
    setIsValidated(true);
    setShowWarning(false);
    if (selectHandler) {
      console.log('selected with handler');
      selectHandler(val);
    }
  };

  const innerChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeHandler(e.target.value);
    setIsValidated(false);
  };

  const addressList1 = [
    '111 ex way',
    '222 ex way',
    '333 ex way',
    '444 ex way',
    '555 ex way',
    '666 ex way',
    '777 ex way',
    '888 ex way',
    '999 ex way',
    '000 ex way',
    '111 asdf way',
    '222 asdf way',
    '333 asdf way',
    '444 asdf way',
    '555 asdf way',
    '666 asdf way',
    '777 asdf way',
    '888 asdf way',
    '999 asdf way',
    '000 asdf way',
  ];

  const checkWarning = () => {
    let localIsVal = isValidated;
    if (optionsList.some((elem: string) => elem === value)) {
      setIsValidated(true);
      localIsVal = true;
    }
    if (localIsVal) {
      setShowWarning(false);
    } else {
      setShowWarning(true);
    }
  };

  const doFetch = useCallback(
    (val: string) => {
      const res = fetch(val);
      if (res === undefined) return;
      res
        .then(d => {
          if (d instanceof Array) {
            // only strings
            setOptionsList(d.filter(val => typeof val === 'string'));
          } else {
            setOptionsList([]);
          }
        })
        .catch(() => {
          if (process.env.REACT_APP_DEBUG === 'true') {
            setOptionsList(addressList1.filter((a: string) => a.includes(val)));
          } else {
            setOptionsList([]);
          }
        });
    },
    [fetch, setOptionsList],
  );

  useEffect(() => {
    doFetch(value);
  }, [value, addressValues, doFetch]);

  return {
    open,
    setOpen,
    optionsList,
    parentId,
    showWarning,
    checkWarning,
    optionSelectHandler,
    innerChangeHandler,
  };
}
