import { gql, useMutation } from '@apollo/client';
import React, { ReactElement, useState } from 'react';
import { Redirect, useParams, withRouter } from 'react-router-dom';
import ForgetPWPagesBackground from '../../components/forgetPWPagesBackground/forgetPWPagesBackground';
import GenericAlert from '../../components/genericAlert/genericAlert';
import GenericButton from '../../components/genericButton/genericButton';
import InputField from '../../components/inputField/inputField';
import style from './forgotPasswordReset.module.css';

interface Props {
  location?: any;
}

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword(
    $email: String!
    $confirmPassword: String!
    $newPassword: String!
    $resetCode: String!
  ) {
    resetPassword(
      email: $email
      confirmPassword: $confirmPassword
      newPassword: $newPassword
      resetCode: $resetCode
    ) {
      success
      error
    }
  }
`;

// const colorStyle = SchemeDecider();
export function ForgotPasswordReset(props: Props): ReactElement {
  const { location } = props;
  const [newPass, setNewPass] = useState<string>('');
  const [confPass, setConfPass] = useState<string>('');
  const [redirect, setRedirect] = useState<string>('');
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const { identifier } = useParams<{ identifier: string }>();

  const [doReset] = useMutation(RESET_PASSWORD_MUTATION, {
    onError: e => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: d => {
      if (d.resetPassword.success) {
        setRedirect('/login');
      } else {
        setAlert(d.resetPassword.error);
        setAlertColor('red');
      }
    },
  });

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  if (!identifier) {
    setRedirect('/forgot-password/verification-code');
  }

  return (
    <ForgetPWPagesBackground>
      <div>
        <h3 className={`${style.box__mainTitleRight} textColorMain `}>
          Forgot Password?
        </h3>
        <h5 className={`${style.box__subTitle} textColorDark `}>
          Please enter your new password below
        </h5>
        <form
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (!newPass) {
              setAlert('Please fill out the required information');
              setAlertColor('red');
              return;
            }
            if (newPass !== confPass) {
              setAlert('Those passwords do not match');
              setAlertColor('red');
              return;
            }

            if (location?.state?.email) {
              doReset({
                variables: {
                  email: location.state.email,
                  confirmPassword: confPass,
                  newPassword: newPass,
                  resetCode: identifier,
                },
              });
            } else {
              setRedirect('/forgot-password');
            }
          }}
        >
          <div className={style.alert}>
            <div className={style.alertBox}>
              <GenericAlert color={alertColor} title={alert} hidden={!alert} />
            </div>
          </div>
          <div className={style.box__inputLong}>
            <div className={style.box__inputField}>
              <InputField
                smallSize
                highlightOnFocus
                htmlFor="new-pass-inpt"
                labelTitle="Enter your new password"
                inputType="password"
                inputValue={newPass}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setNewPass(e.target.value);
                }}
              />
            </div>
            <div className={style.box__inputField}>
              <InputField
                smallSize
                highlightOnFocus
                htmlFor="cont-pass-inpt"
                labelTitle="Confirm your new password"
                inputType="password"
                inputValue={confPass}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setConfPass(e.target.value);
                }}
              />
            </div>
          </div>
          <div className={style.box__btnRight}>
            <div className={style.box__btnRightInnerBox}>
              <GenericButton
                size="medium"
                color="blue"
                title="Reset My Password"
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    </ForgetPWPagesBackground>
  );
}
ForgotPasswordReset.defaultProps = {
  location: undefined,
};
export default withRouter(ForgotPasswordReset);
