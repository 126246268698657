import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

export default function useContainerBlur(
  containerId: string,
  options?: {
    defaultState?: boolean;
    autoCloseInnerButton?: boolean;
    autoOpen?: boolean;
  },
): {
  open: boolean;
  containerId: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
} {
  const prepedOptions = useMemo(() => {
    return {
      defaultState: false,
      autoCloseInnerButton: false,
      autoOpen: false,
      ...options,
    };
  }, [options]);
  const [open, setOpen] = useState<boolean>(prepedOptions.defaultState);
  useEffect(() => {
    const handler = (ev: MouseEvent) => {
      if (!open && !prepedOptions.autoOpen) return;
      let tmp = ev.target as HTMLElement;
      const parent = document.getElementById(containerId);
      if (parent) {
        while (tmp) {
          if (open && tmp.tagName === 'BUTTON' && prepedOptions.autoCloseInnerButton)
            setOpen(false);
          if (tmp === parent) {
            setOpen(true);
            return;
          }
          tmp = tmp.parentElement as HTMLElement;
        }
        if (open) setOpen(false);
      }
    };
    document.addEventListener('click', handler);
    return () => document.removeEventListener('click', handler);
  }, [open, containerId, prepedOptions]);

  return {
    open,
    containerId,
    setOpen,
  };
}
