import { useContext, useEffect, useState } from 'react';
import { CommunityContext } from '../communityConfigs/communityContextProvider';
import store from './store';

const allScripts = {
  resident:
    "(function(g,u,i,d,e,s){g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];var k=u.createElement(i);k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};ug.c=function(n){return function(){ug.q.push([n,arguments])};};var m=['previewGuide','finishPreview','track','identify','triggerNps','hideChecklist','launchChecklist'];for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer','854289734ID');",
  vendor:
    "(function(g,u,i,d,e,s){g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];var k=u.createElement(i);k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};ug.c=function(n){return function(){ug.q.push([n,arguments])};};var m=['previewGuide','finishPreview','track','identify','triggerNps','hideChecklist','launchChecklist'];for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer','765806158ID');",
};

export default function useUserGuiding(
  scriptName = 'resident' as keyof typeof allScripts,
): void {
  const scriptId = 'user-guiding-script';
  const { communityId } = useContext(CommunityContext);
  const [user, setUser] = useState(store.getState().user);
  useEffect(
    () =>
      store.subscribe(() => {
        setUser(store.getState().user);
      }),
    [setUser],
  );

  useEffect(() => {
    const script = document.createElement('script');
    let scriptContent = '';
    switch (scriptName) {
      case 'resident':
        scriptContent = allScripts.resident;
        break;
      case 'vendor':
        scriptContent = allScripts.vendor;
        break;
    }
    if (!scriptContent) return;
    script.id = scriptId;
    script.innerHTML = scriptContent;
    document.body.appendChild(script);
    return () => {
      const elem = document.getElementById(scriptId);
      if (elem) {
        document.body.removeChild(elem);
      }
    };
  }, [scriptName]);

  useEffect(() => {
    if (user) {
      (window as any).userGuiding.identify(user?.userId, {
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
        communityId,
      });
    }
  }, [user, communityId]);
}
