import { ReactChild, ReactElement, useContext } from 'react';
import { CommunityContext } from '../../communityConfigs/communityContextProvider';
import lbgStyle from './forgetPWPagesBackground.module.css';

interface Props {
  children?: ReactChild | Array<ReactChild>;
}

export default function ForgetPWPagesBackground(props: Props): ReactElement {
  const { communityId } = useContext(CommunityContext);
  const { children } = props;
  const { bigLogo } = useContext(CommunityContext);

  return (
    <div className={lbgStyle.container}>
      <div className={`${lbgStyle.box} white `}>
        <div className={`${lbgStyle.box__left}  linerGradient `}>
          <img
            src={bigLogo}
            alt=""
            className={
              communityId === 'watercolor' ||
              communityId === 'oceanlakes' ||
              communityId === 'isleworth' ||
              communityId === 'pinnacleport' ||
              communityId === 'debordieu' ||
              communityId === 'carillonbeach'
                ? lbgStyle.box__imgPostion
                : lbgStyle.box__img
            }
          />
        </div>
        <div className={lbgStyle.box__right}>{children}</div>
      </div>
    </div>
  );
}
ForgetPWPagesBackground.defaultProps = {
  children: <></>,
};
