import { QueryHookOptions, QueryResult, gql, useQuery } from '@apollo/client';
import { backendResponse } from '../types/backendResponse';

const GET = gql`
  query GetCompanyVehicles {
    getCompanyVehicles {
      success
      error
      data {
        color
        fleetNumber
        isRental
        licensePlate
        licensePlateState
        licensePrimaryDriver
        make
        primaryDriverName
        type
        userId
        vehicleModel
        vehicleId
        year
        insuranceDocUrl
        registrationDocUrl
        regDocFileName
        insDocFileName
      }
    }
  }
`;

type GET_VARS = Record<string, never>; // this typdedef is equivalent to an objct with no keys
type GET_RES = {
  getCompanyVehicles: backendResponse<
    {
      color: string;
      fleetNumber: string;
      isRental: string;
      licensePlate: string;
      licensePlateState: string;
      licensePrimaryDriver: string;
      make: string;
      primaryDriverName: string;
      type: string;
      userId: string;
      vehicleModel: string;
      vehicleId: string;
      year: number;
      insuranceDocURL?: string;
      registrationDocUrl?: string;
    }[]
  >;
};

export const useCompanyVehicles = (
  options?: QueryHookOptions<GET_RES, GET_VARS>,
): QueryResult<GET_RES, GET_VARS> => useQuery<GET_RES, GET_VARS>(GET, options);
