import { gql, useLazyQuery } from '@apollo/client';
import React, { ReactElement, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import ForgetPWPagesBackground from '../../components/forgetPWPagesBackground/forgetPWPagesBackground';
import GenericAlert from '../../components/genericAlert/genericAlert';
import GenericButton from '../../components/genericButton/genericButton';
import InputField from '../../components/inputField/inputField';
import { RESET_PASSWORD_EMAIL_QUERY } from '../forgotPasswordEmail/forgotPasswordEmail';
import style from './forgotPasswordVerification.module.css';

interface Props {
  location?: any;
}

export const VERIFY_RESET_CODE_QUERY = gql`
  query VerifyResetCode($email: String!, $resetCode: String!) {
    verifyResetCode(email: $email, resetCode: $resetCode) {
      success
      error
    }
  }
`;

export function ForgotPasswordVerification(props: Props): ReactElement {
  const { location } = props;
  const [code, setCode] = useState<string>('');
  const [redirect, setRedirect] = useState<
    string | { pathname: string; state: { email: string } }
  >('');
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');

  const [doResend] = useLazyQuery(RESET_PASSWORD_EMAIL_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: d => {
      if (d.sendResetCode.success) {
        setAlert('Verification email has been sent');
        setAlertColor('green');
      }
    },
  });

  const [doVerify] = useLazyQuery(VERIFY_RESET_CODE_QUERY, {
    fetchPolicy: 'network-only',
    onError: e => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: d => {
      if (d.verifyResetCode.success) {
        setRedirect({
          pathname: `/forgot-password/reset-password/${code || ''}`,
          state: {
            email: location.state.email,
          },
        });
      } else {
        setAlert(d.verifyResetCode.error);
        setAlertColor('red');
      }
    },
  });

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <ForgetPWPagesBackground>
      <div>
        <h3 className={`${style.box__mainTitleRight} textColorMain `}>
          Forgot Password?
        </h3>
        <h5 className={`${style.box__subTitle} textColorDark `}>
          Please enter the verification code
        </h5>
        <form
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            if (location?.state?.email) {
              doVerify({
                variables: {
                  resetCode: code,
                  email: location.state.email,
                },
              });
            } else {
              setRedirect('/forgot-password');
            }
          }}
        >
          <div className={style.alert}>
            <div className={style.alertBox}>
              <GenericAlert color={alertColor} title={alert} hidden={!alert} />
            </div>
          </div>

          <div className={style.box__inputLong}>
            <div className={style.box__inputField}>
              <InputField
                smallSize
                highlightOnFocus
                htmlFor="code-inpt"
                labelTitle="Verification Code"
                inputType="text"
                inputValue={code}
                changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCode(e.target.value);
                }}
              />
            </div>
          </div>

          <div className={style.box__btnRight}>
            <div className={style.emailBox}>
              <GenericButton
                outline="no-border"
                clickHandler={() => {
                  if (location?.state?.email) {
                    doResend({
                      variables: { email: props.location.state.email },
                    });
                  } else {
                    setRedirect('/forgot-password');
                  }
                }}
                title="Did not receive an email?"
              />
            </div>
            <div className={style.box__btnRightInnerBox}>
              <GenericButton size="medium" color="blue" title="Enter" type="submit" />
            </div>
          </div>
        </form>
      </div>
    </ForgetPWPagesBackground>
  );
}
ForgotPasswordVerification.defaultProps = {
  location: undefined,
};
export default withRouter(ForgotPasswordVerification);
