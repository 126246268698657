import { gql } from '@apollo/client';
import { backendResponse } from '../../types/backendResponse';
import { backendClient } from '../../utilities/BackendAPI';

const GET_REGISTRATION = gql`
  query GetRegistrationStatus($registrationId: String!) {
    getRegistration(registrationId: $registrationId) {
      success
      error
      data {
        complete
      }
    }
  }
`;
type GET_REGISTRATION_VARS = {
  registrationId: string;
};
type GET_REGISTRATION_RES = {
  getRegistration: backendResponse<
    {
      complete: boolean;
    }[]
  >;
};

export const getRegistration = (variables: GET_REGISTRATION_VARS) =>
  backendClient.query<GET_REGISTRATION_RES, GET_REGISTRATION_VARS>({
    query: GET_REGISTRATION,
    variables,
    fetchPolicy: 'no-cache',
  });
