import React, { ReactElement, useState } from 'react';
import inptStyle from './inputField.module.css';
import useFormatter from './useFormatter';

interface InputFieldProps {
  autoComplete?: boolean;
  htmlFor: string;
  labelTitle?: string;
  inputType?: string;
  formatCode?: string;
  inputPlaceholder?: string;
  inputValue?: string | undefined;
  changeHandler?: ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  labelStyle?: string;
  inputStyle?: string;
  smallSize?: boolean;
  largeSize?: boolean;
  lightText?: boolean;
  closedInput?: boolean;
  checkBox?: boolean;
  radio?: boolean;
  required?: boolean;
  // enables or disables style changeing on fucus and blur
  highlightOnFocus?: boolean;
  disabled?: boolean;
  checked?: boolean;
  step?: string;
  min?: string;
  max?: string;
  minLength?: number;
  maxLength?: number;
}

export const InputField = (props: InputFieldProps): ReactElement => {
  // tracks if the element is currently focused
  const [focused, setFocus] = useState<boolean>(false);
  const {
    labelTitle,
    htmlFor,
    inputType,
    formatCode,
    inputPlaceholder,
    changeHandler,
    inputValue,
    labelStyle,
    inputStyle,
    smallSize,
    largeSize,
    lightText,
    closedInput,
    checkBox,
    radio,
    required,
    highlightOnFocus,
    disabled,
    checked,
    step,
    min,
    max,
    minLength,
    maxLength,
    autoComplete,
  } = props;

  const formatterHandler = useFormatter(htmlFor, formatCode, changeHandler);

  /// / determine css for component
  let inptCname = inputStyle;
  let labelCname = labelStyle;
  if (smallSize) {
    labelCname = ` ${inptStyle.labelSmall} textColorMedium `;
    inptCname = ` ${inptStyle.smallSize} textColorSuperDark `;
  } else if (largeSize) {
    labelCname = ` ${inptStyle.labelLarge} textColorMedium `;
    inptCname = ` ${inptStyle.largeSize} textColorSuperDark `;
  } else if (closedInput) {
    labelCname = ` ${inptStyle.labelClosedInput} textColorSuperDark `;
    inptCname = ` ${inptStyle.closedInput} textColorMedium `;
  } else if (checkBox) {
    labelCname = ` ${inptStyle.labelCheckBox} textColorSuperDark `;
    inptCname = ` ${inptStyle.checkBox} textColorMedium `;
  } else if (radio) {
    labelCname = ` ${inptStyle.labelRadio} textColorSuperDark `;
    inptCname = ` ${inptStyle.radio} textColorMedium `;
  }

  if (lightText) {
    labelCname += ' textColorSecondary ';
    inptCname += ' textColorSecondary ';
  }
  if (focused && highlightOnFocus) {
    // TODO add css for a focused element here
    labelCname += ' textColorMain ';
    inptCname += ' textColorMain ';
  }

  const trimValue = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.target.value = e.target.value.trim();
    formatterHandler(e);
  };

  const blurHandler = (e: React.FocusEvent<HTMLInputElement>): void => {
    setFocus(false);
    trimValue(e);
  };

  const keyPressHandler = (key: string, e: React.ChangeEvent<HTMLInputElement>): void => {
    if (key === 'Enter') {
      trimValue(e);
    }
  };

  return (
    <label htmlFor={htmlFor} className={labelCname}>
      <span>{labelTitle}</span>
      <div className={inptStyle.inputBox}>
        <input
          className={inptCname}
          type={inputType}
          placeholder={inputPlaceholder}
          value={inputValue}
          id={htmlFor}
          onChange={formatterHandler}
          onFocus={() => setFocus(true)}
          onBlur={blurHandler}
          onKeyPress={(e: any) => keyPressHandler(e.key, e)}
          // edge case if disabled and required, make not required
          required={required && !disabled}
          disabled={disabled}
          checked={checked}
          step={step || inputType === 'number' ? 'any' : undefined}
          min={min}
          max={max}
          minLength={minLength}
          maxLength={maxLength}
          autoComplete={autoComplete ? 'on' : 'somerandomstring'}
        />
      </div>
    </label>
  );
};
InputField.defaultProps = {
  labelTitle: '',
  inputType: 'text',
  formatCode: 'none',
  inputPlaceholder: '',
  inputValue: undefined,
  changeHandler: undefined,
  labelStyle: ' default_style',
  inputStyle: ' default_style',
  smallSize: false,
  largeSize: false,
  closedInput: false,
  checkBox: false,
  lightText: false,
  required: false,
  highlightOnFocus: false,
  disabled: false,
  checked: false,
  step: undefined,
  min: undefined,
  max: undefined,
  minLength: undefined,
  maxLength: undefined,
  autoComplete: false,
};

export default InputField;
