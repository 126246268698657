export type hostInfo = {
  ownerPin: string;
  zipCode: string;
  state: string;
  city: string;
  address: string;
  phoneNumber: string;
  lastName: string;
  firstName: string;
  companyName: string;
  country: string;
};

export function newHostInfo(data?: any): hostInfo {
  return {
    ownerPin: data?.ownerPin || '',
    zipCode: data?.zipCode || '',
    state: data?.state || '',
    city: data?.city || '',
    address: data?.address || '',
    phoneNumber: data?.phoneNumber || '',
    lastName: data?.lastName || '',
    firstName: data?.firstName || '',
    companyName: data?.companyName || '',
    country: data?.country || '',
  };
}
