interface CollectionToolsProps<DataType> {
  newElement: (data?: any) => DataType;
  data: Array<DataType>;
  setData: (data: Array<DataType>) => void;
}
interface CollectionToolsReturn {
  add: (data?: any) => void;
  update: (idx: number, obj: any) => void;
  remove: (idx: number) => void;
}
export default function collectionTools<DataType>(
  props: CollectionToolsProps<DataType>,
): CollectionToolsReturn {
  const { newElement, data, setData } = props;

  const add = (newData?: any) => {
    const res = [...data];
    res.push(newElement(newData));
    setData(res);
  };

  const isObject = (item: any) =>
    item && typeof item === 'object' && !Array.isArray(item);
  const mergeDeep = (target: any, ...sources: Array<any>): void => {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
      // for (const key in source) {
      Object.keys(source).forEach((key: string) => {
        if (isObject(source[key])) {
          if (!target[key]) {
            Object.assign(target, {
              [key]: {},
            });
          }
          mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, {
            [key]: source[key],
          });
        }
      });
    }
    return mergeDeep(target, ...sources);
  };
  const update = (idx: number, obj: any) => {
    const res = [...data];
    // Object.assign(res[idx], obj);
    mergeDeep(res[idx], obj);
    setData(res);
  };

  const remove = (idx: number) => {
    const res = [...data];
    res.splice(idx, 1);
    setData(res);
  };

  return {
    add,
    update,
    remove,
  };
}
