import { ApolloClient, InMemoryCache, createHttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getCurrentPortalUrls } from '../configs/symlivUrls';
import store from './store';
import { getAndSetToken, logout } from './thunks';

/**
 * Client and configuration for apollo cache library
 *
 * Special cases and variables:
 *    When the environment variable "REACT_APP_BACKEND_URL" is set
 *        That backend url will be used regardless of other logic that
 *        would otherwise determine it
 *    Requests with the operationName "GetCommunityToken"
 *        These requests will not have an authorization header added
 *    Requests with the variable "__cfg_no_token" set to true
 *        These requests will not have an authorization header added
 */

/**
 * getLinkUri
 * function to automatically determine backend url
 *
 * Special cases and variables:
 *    When the environment variable "REACT_APP_BACKEND_URL" is set
 *        That backend url will be used regardless of other logic that
 *        would otherwise determine it
 */
const getLinkUri = (): string => {
  // including REACT_APP_BACKEND_URL in the .env file will override
  // the logic to automatically select the backend url based on frontend
  if (process?.env?.REACT_APP_BACKEND_URL) {
    return process.env.REACT_APP_BACKEND_URL;
  }
  return getCurrentPortalUrls().back;
};

const httpLink = createHttpLink({
  uri: getLinkUri(),
});

/**
 * checkAuthLink
 * Function to add authorization header to requests.
 * If the token does not exist, it is fetched
 *
 * Special cases and variables:
 *    Requests with the operationName "GetCommunityToken"
 *        These requests will not have an authorization header added
 *    Requests with the variable "__cfg_no_token" set to true
 *        These requests will not have an authorization header added
 *
 */
const checkAuthLink = setContext(async (request, previousContext) => {
  let { token } = store.getState();
  if (request.operationName === 'GetCommunityToken') {
    return previousContext;
  }
  if (request.variables?.__cfg_no_token) return;
  if (!token) {
    token = await (store.dispatch(getAndSetToken()) as unknown as Promise<string>);
  }
  return {
    ...previousContext,
    headers: {
      authorization: `Bearer ${token || ''}`,
    },
  };
});

/**
 * errorCheckLink
 * function to take certain actions on certain special case error messages
 *
 * Special cases:
 *    if the error message starts with "Token Error",
 *        the user will be automatically logged out.
 *
 */
const errorCheckLink = onError(({ graphQLErrors, operation }) => {
  if (graphQLErrors && operation.operationName !== 'GetCommunityToken') {
    if (graphQLErrors.some(({ message }) => message.startsWith('Token Error'))) {
      if (process.env.REACT_APP_DEBUG !== 'true') {
        store.dispatch(logout);
      }
    }
    if(graphQLErrors && graphQLErrors.length > 0) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(message);
      });
    }
  }
});

/**
 * create and configure backend client
 * add middleware defined above
 */
export const backendClient = new ApolloClient({
  // link: authLink.concat(httpLink),
  // link: authLink,
  cache: new InMemoryCache(),
  link: from([
    checkAuthLink,
    // authLink,
    errorCheckLink,
    httpLink,
  ]),
});

/**
 * Helper function to merge variable programatically
 *
 * This is not used in vendor portal. Can it be removed?
 */
export function mergeVariables(orig: any, ...vars_: Array<any>): any {
  // console.log(orig);
  // console.log(vars_);
  const emptyPaterns: Array<any> = [''];
  const res: any = { ...orig };
  const vars = JSON.parse(JSON.stringify(vars_));
  for (let i = 0; i < vars.length; i += 1) {
    Object.keys(vars[i]).forEach((key: string) => {
      if (emptyPaterns.includes(vars[i][key])) {
        delete vars[i][key];
      }
    });
    Object.assign(res, vars[i]);
  }
  return res;
}

/**
 * Helper function to assign null values to falsy variable results.
 * Helpfull to prevent setting attributes to empty strings
 *
 */
export function checkNull(obj: any): any {
  const res: any = {};
  Object.entries(obj).forEach((entry: Array<any>) => {
    res[entry[0]] = entry[1] || null;
  });
  return res;
}
